<template>
  <div id="home">
    <!--    20231024 lgu+ 가입/해지 불가 모달-->
    <!--    <LayerPopup v-if="dispLgupPopup" :popId="'notice_popup'">
      <template v-slot:popContents>
        <div class="contents">
          <h4>LG U+ 시스템 점검 안내</h4>
          <div class="pop_contents">
            LG U+ 이동통신사에서 진행하는 시스템 점검에 의해 LG U+ 이용 고객의 서비스 가입/해지가 일시 중단될
            예정입니다.<br /><br />
            ▣ 점검 기간<br />
            2023년 10월 20일(금) 22:00 ~ 2023년 10월 23일(월) 08:00<br /><br />
            자세한 내용은 공지사항 확인을 부탁 드립니다.<br /><br />
            더 좋은 서비스로 보답하겠습니다.
          </div>
          <ul class="pop_btns">
            <li><button type="button" class="pop_btn cancel" @click="hideLayerPopup">취소</button></li>
            <li><button type="button" class="pop_btn access" @click="noticeDetail">자세히 보기</button></li>
          </ul>
        </div>
      </template>
    </LayerPopup>-->
    <Banner v-if="!$cookies.get('HomePop') && $store.state.homePop && $store.state.homePop.ImgPath" />
    <HomeSection0 />
    <HomeSection1 />
    <YoutubeVideo />
    <HomeSection2 />
    <HomeSection3 />
    <HomeSection4 />
  </div>
</template>

<script>
import constant from "@/service/constant/store"
import Banner from "@/components/Banner.vue"
import HomeSection0 from "@/components/Home/HomeSection0.vue"
import HomeSection1 from "@/components/Home/HomeSection1.vue"
import HomeSection2 from "@/components/Home/HomeSection2.vue"
import HomeSection3 from "@/components/Home/HomeSection3.vue"
import HomeSection4 from "@/components/Home/HomeSection4.vue"
import YoutubeVideo from "@/components/Home/YoutubeVideo"

export default {
  name: "Home",
  components: {
    YoutubeVideo,
    Banner,
    HomeSection0,
    HomeSection1,
    HomeSection2,
    HomeSection3,
    HomeSection4,
  },
  data() {
    return {
      sectionTop: null,
      // 20231024 lgu+ 가입/해지 불가 모달
      // dispLgupPopup: true,
    }
  },
  mounted() {
    this.$store.dispatch(constant.FETCH_SERV_IMGSRC)
    this.fetchHomeData()
    const header = document.querySelector("#header")
    const secArr = Array.prototype.slice.call(document.querySelectorAll(".home_sec"))
    this.sectionTop = secArr.map((item, idx) => {
      let subtract = 0
      if (idx !== 0) subtract = secArr[idx - 1].offsetHeight / 3
      return item.offsetTop - subtract - header.offsetHeight
    })
    window.addEventListener("scroll", this.handleHomeScroll)
    window.addEventListener("scroll", this.$handleFixDown)
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleHomeScroll)
    window.removeEventListener("scroll", this.$handleFixDown)
  },
  methods: {
    handleHomeScroll() {
      const scrollTop = document.documentElement.scrollTop
      this.sectionTop.forEach((secTop, secIdx) => {
        const secEle = document.querySelector(`.sec${secIdx}`)
        if (scrollTop >= secTop) secEle.classList.add("active")
        else secEle.classList.remove("active")
      })
    },
    async fetchHomeData() {
      const httpData = {
        Header: {
          CmdType: "010012",
          Browser: "CHROME",
          LocalIP: "192.0.0.0",
          RequestID: this.$guid(),
        },
        Body: {
          Length: 4,
          Offset: 0,
        },
      }
      try {
        const { data } = await this.$SRSServer(httpData)
        const { News, Notice, Popup } = data.Body
        this.$store.commit(constant.SET_MAIN_NOTICELATEST, Notice)
        this.$store.commit(constant.SET_MAIN_NEWSLATEST, News)
        this.$store.commit(constant.SET_MAIN_HOMEPOP, Popup)
      } catch (error) {
        console.log(error)
      }
    },
    // 20231024 lgu+ 가입/해지 불가 모달
    /*hideLayerPopup() {
      this.dispLgupPopup = false
    },
    noticeDetail() {
      this.dispLgupPopup = false
      const index = window.location.href.indexOf("local") > 0 || window.location.href.indexOf("dev") > 0 ? 19 : 72
      this.$router.push(`/notice/detail/${index}`)
    },*/
  },
  created() {
    // 20231024 lgu+ 가입/해지 불가 모달
    /*const sd = dayjs("2023-10-16 00:00:00")
    const ed = dayjs("2023-10-23 08:00:00")
    const now = dayjs()
    this.dispLgupPopup = now.isAfter(sd) && now.isBefore(ed)*/
  },
}
</script>
