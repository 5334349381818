<template>
  <section class="home_sec sec3">
    <div class="home_wrap">
      <div class="home_desc">
        <div>
          <strong class="tit">아직 할부도 한참 남았는데..<br />잃어버릴까 걱정되세요?</strong>
          <p>
            몇 달 전에 구입한 내 최신 휴대폰, 단말기 가격만 백만원이 넘는데...<br />
            혹시나 도난 당하거나, 실수로 잃어버릴까봐 너무 걱정 되시나요?
          </p>
        </div>
        <span class="img"><img src="@/assets/images/sec1_img0.png" alt="" /></span>
      </div>
      <div class="home_desc">
        <div>
          <strong class="tit"><b>휴대폰분실보호 앱 설치</b>하고<br />분실 전에 미리 보호하세요!</strong>
          <p>휴대폰 분실 시, 신속하게 실시간 위치를 조회하고 저장된 개인정보를 안전하게 보호할 수 있습니다.</p>
        </div>
        <span class="img"><img src="@/assets/images/main-section-2-2.png" alt="" /></span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSection2",
}
</script>
