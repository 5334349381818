<template>
  <section class="home_sec sec4">
    <h3><span>휴대폰분실보호 서비스</span>어떻게 이용하나요?</h3>
    <div class="home_wrap">
      <section class="tab_menu">
        <button type="button" class="tab_btn" :class="tabIdx === 0 && 'now'" @click="handleTabMenu(0)">가입방법</button>
        <button type="button" class="tab_btn" :class="tabIdx === 1 && 'now'" @click="handleTabMenu(1)">
          분실폰 찾기
        </button>
      </section>
      <section class="tab_wrap">
        <template v-if="tabIdx === 0">
          <swiper ref="tabSwiper" class="tab_slide install" :options="swiperOption">
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <swiper-slide v-for="(item, idx) in slidesArr.install" :key="idx">
              <span class="img"></span>
              <p class="text" v-html="item.text"></p>
            </swiper-slide>
          </swiper>
        </template>
        <!-- //미가입자 -->
        <template v-else>
          <swiper ref="tabSwiper" class="tab_slide used" :options="swiperOption">
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <swiper-slide v-for="(item, idx) in slidesArr.used" :key="idx">
              <span class="img"></span>
              <p class="text" v-html="item.text"></p>
            </swiper-slide>
          </swiper>
        </template>
        <!-- //기가입자 -->
      </section>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.css"

export default {
  name: "HomeSection3",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      tabIdx: 0,
      swiperOption: {
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      slidesArr: {
        install: [
          {
            text: "구글플레이 스토어를 통해 ‘휴대폰분실보호’ 앱을 설치하세요.<small>아이폰 사용자용 서비스는 추후 업데이트 될 예정입니다.</small>",
          },
          {
            text: "설치 완료 되면, ‘휴대폰분실보호’ 앱을 실행하세요.",
          },
          {
            text: "기능별 접근권한을 모두 허용해주세요.",
          },
          {
            text: "본인 확인을 위해 휴대폰 인증을 진행해 주세요.",
          },
          {
            text: "이용약관 동의 후 서비스 가입을 완료하세요.",
          },
          {
            text: "PIN번호를 설정하세요 : 잠금모드 해제, [분실폰 찾기] 로그인시 사용됩니다.",
          },
          {
            text: "습득자가 연락할 수 있도록 비상연락처를 설정해주세요.<small>데이터 백업/복원을 이용하기 위해서 구글 드라이브 연동이 필요합니다.</small>",
          },
        ],
        used: [
          {
            text: "휴대폰분실보호 [분실폰 찾기] 웹사이트 ( https://svc.mfinder.co.kr) 에 접속합니다.",
          },
          {
            text: "서비스 가입된 휴대폰 번호와 앱 설치시 등록한 PIN번호 6자리를 입력 후, 로그인 하세요.",
          },
          {
            text: "로그인과 동시에, 서비스에 가입된 휴대폰은 잠금모드가 실행됩니다.",
          },
          {
            text: "휴대폰분실보호 서비스가 제공하는 기능을 이용해 분실폰을 쉽고 빠르게 찾아보세요.",
          },
        ],
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.tabSwiper.$swiper
    },
  },
  methods: {
    handleTabMenu(idx) {
      this.tabIdx = idx
      this.swiper.slideTo(0)
    },
  },
}
</script>
