<template>
  <section class="home_sec sec5">
    <h3><span>2021 대한민국 모바일 대상</span>스타트업 보안 부문<br /><b>‘최우수상’ 수상</b></h3>
    <div class="home_wrap">
      <div class="logo">
        <span class="img"></span>
      </div>
      <div class="review">
        <h4>구글 스토어 리뷰</h4>
        <ul class="review_list">
          <li v-for="n in 4" :key="n" class="img"></li>
        </ul>
        <ul class="dots">
          <li v-for="n in 3" :key="n"></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSection4",
}
</script>
