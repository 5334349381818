<template>
  <section class="home_sec sec2">
    <div class="home_wrap">
      <article class="youtube_article">
        <iframe
          width="100%"
          height="203px"
          src="https://www.youtube.com/embed/_kavbpAHE9w?controls=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: "YoutubeVideo",
}
</script>
