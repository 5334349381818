<template>
  <LayerPopup :popId="'banner_pop'">
    <template v-slot:popContents>
      <div class="contents">
        <div class="pop_contents">
          <a class="img" :href="$store.state.homePop.LinkPath" target="_blank">
            <img
              :src="`${$store.state.servImgSRC}/${$store.state.homePop.ImgPath}`"
              :alt="$store.state.homePop.PopTitle"
            />
          </a>
        </div>
        <div class="pop_close">
          <button type="button" class="close_today" @click="closeToday">오늘 하루 닫기</button>
          <button type="button" class="close_once" @click="closeNow"><i class="hide">닫기</i></button>
        </div>
      </div>
    </template>
  </LayerPopup>
</template>

<script>
import constants from "@/service/constant/store"
import LayerPopup from "@/components/LayerPopup.vue"

export default {
  name: "Banner",
  components: {
    LayerPopup,
  },
  methods: {
    closeToday() {
      const now = new Date()
      const tomorrow = new Date(now.setDate(now.getDate() + 1))
      this.$cookies.set("HomePop", now, tomorrow)
      this.$store.commit(constants.CLEAR_HOMEPOP)
    },
    closeNow() {
      this.$store.commit(constants.CLEAR_HOMEPOP)
    },
  },
}
</script>
