<template>
  <section class="home_sec sec1">
    <div class="home_wrap">
      <section class="sec_wrap">
        <h4 class="notice">
          <strong>공지사항</strong>
          <router-link to="/notice">더보기<i class="icon_more"></i></router-link>
        </h4>
        <ol class="list_notice">
          <template v-for="(noticeItem, itemIdx) in $store.state.noticeLatest">
            <li v-if="itemIdx < 6" :key="noticeItem.Idx">
              <router-link :to="`notice/detail/${noticeItem.Idx}`">{{ noticeItem.Title }}</router-link>
            </li>
          </template>
        </ol>
      </section>
      <section class="sec_wrap">
        <h4 class="news">
          <strong>MFinder 소식</strong>
          <router-link to="/news">더보기<i class="icon_more"></i></router-link>
        </h4>
        <ol class="list_news">
          <li v-for="newsItem in $store.state.newsLatest" :key="newsItem.Idx" class="news_item">
            <a :href="newsItem.LinkPath" class="img" target="_blank">
              <img :src="`${$store.state.servImgSRC}${newsItem.ImgPath}`" alt="" />
              <p class="desc" :class="Number(newsItem.NewsType) === 0 ? 'report' : 'blog'">
                <strong>{{ newsItem.NewsTitle }}</strong>
              </p>
            </a>
          </li>
        </ol>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeSection1",
}
</script>
