<template>
  <section class="home_sec sec0">
    <div class="home_wrap">
      <h3>휴대폰을 잃어버렸나요?</h3>
      <swiper class="sec0_slide" :options="swiperOption">
        <div class="swiper-pagination" slot="pagination"></div>
        <swiper-slide>
          <div class="slide_wrap home_desc">
            <strong class="tit">분실폰의 회수를 위한<br />다양한 기능을 사용해보세요.</strong>
          </div>
          <span class="img"><img src="@/assets/images/sec0_slide0.png" alt="" /></span>
        </swiper-slide>
        <swiper-slide>
          <div class="slide_wrap home_desc">
            <strong class="tit">분실폰의 위치와 실시간 주변<br />환경을 확인할 수 있습니다.</strong>
          </div>
          <span class="img"><img src="@/assets/images/sec0_slide1.png" alt="" /></span>
        </swiper-slide>
        <swiper-slide>
          <div class="slide_wrap home_desc">
            <strong class="tit">분실폰의 스피커를 통해 회수요청 음성 메시지를 전달할 수 있습니다.</strong>
          </div>
          <span class="img"><img src="@/assets/images/sec0_slide2.png" alt="" /></span>
        </swiper-slide>
        <swiper-slide>
          <div class="slide_wrap home_desc">
            <strong class="tit">분실폰의 데이터를 백업하고 개인정보는 안전하게 삭제해보세요.</strong>
          </div>
          <span class="img"><img src="@/assets/images/sec0_slide3.png" alt="" /></span>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import "swiper/css/swiper.css"

export default {
  name: "HomeSection0",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        speed: 500,
        slidesPerView: "1",
        centeredSlides: true,
        autoplay: {
          delay: 3700,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    }
  },
}
</script>
